import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CLocalBtnList,
  CSectTitle03,
  CFaqList,
  CBreadCrumb,
  LFaqContact,
  CAnchorNav,
} from '../../../components/_index';
import '../../../assets/_sass/page/faq.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'よくあるご質問',
            sub: 'FAQ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_faq">
        <section className="u_mtMedium">
          <LWrap>
            <CLocalBtnList
              data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: '/faq/',
                  },
                },
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: '/faq/restaurants/',
                  },
                },
                {
                  label: 'ウエディング',
                  link: {
                    href: '/faq/wedding/',
                  },
                },
                {
                  label: '宴会・会議',
                  link: {
                    href: '/faq/banquet/',
                  },
                },
                {
                  label: 'お子様連れのお客様',
                  link: {
                    href: '/faq/child/',
                  },
                },
                {
                  label: 'バリアフリー',
                  link: {
                    href: '/faq/accessibility/',
                  },
                },
              ]}
            />
          </LWrap>
          {/* <LWrap size="small">
            <CAnchorNav
              exClass="u_mt50"
              data={[
                {
                  title: <>宿泊</>,
                  link: {
                    href: '#stay',
                  },
                },
                {
                  title: <>レストラン</>,
                  link: {
                    href: '#restaurants',
                  },
                },
                {
                  title: <>設備・サービス</>,
                  link: {
                    href: '#facility',
                  },
                },
                {
                  title: <>アクセス</>,
                  link: {
                    href: '#access',
                  },
                },
              ]}
            />
          </LWrap> */}
        </section>

        <section className="u_pt80" id="stay">
          <LWrap>
            <CSectTitle03 title="宿泊" />
            <CFaqList
              data={[
                {
                  q: '車椅子や高齢の方に対応できるお部屋はありますか?',
                  a: (
                    <>
                      アクセシブルルームのご用意がございます。お部屋の数に限りがありますので事前に空室状況をご確認ください。
                      <br />
                      アクセシブルルームの詳細は
                      <a href="/stay/accessible/">こちら</a>
                      をご確認ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="restaurants">
          <LWrap>
            <CSectTitle03 title="レストラン" />
            <CFaqList
              data={[
                {
                  q: '車椅子のまま利用できるレストランはありますか?',
                  a: <>全レストランにてご利用いただけます。</>,
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="facility">
          <LWrap>
            <CSectTitle03 title="設備・サービス" />
            <CFaqList
              data={[
                {
                  q: '車椅子の貸出はありますか?',
                  a: (
                    <>
                      お貸し出しは可能ですが、数に限りがございます。事前にお問合せください。
                    </>
                  ),
                },
                {
                  q: '車椅子対応の化粧室はありますか?',
                  a: <>1F バリアフリートイレをご利用ください。</>,
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="u_pt80" id="access">
          <LWrap>
            <CSectTitle03 title="アクセス" />
            <CFaqList
              data={[
                {
                  q: '東京駅からの送迎バスはありますか?有料ですか?',
                  a: (
                    <>
                      地下鉄、タクシーなど公共交通機関をご利用ください。
                      <br />
                      また、無料巡回バスもご利用いただけます。詳細は
                      <a href="/about/tourism/">こちら</a>をご確認ください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <LFaqContact />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
